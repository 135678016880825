* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body, button {
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	text-rendering: optimizeLegibility;
	color: #444;
}

textarea {
	resize: vertical;
	font-family: "Roboto", sans-serif;
}


.a-button {
	line-height: 4;
}

.a-button:active {
	transform: scale(1.04) !important;
	background: var(--actiont);
}

button, .a-button {
	border: none;
	cursor: pointer;
	font-size: 18px;
	text-decoration: none !important;
	background: var(--action);
	padding: 14px 24px;
	font-weight: 600;
	border-radius: 0.375rem;
	color: white;
	transition: transform, background, 40ms ;

	&:active {
		transform: scale(1.04);
		background: var(--actiont);
	}
}
:root {
	// --action: #4AD66D;
	--action: #0073ff;
	--secondary: #f2f2f2;
	--actiont: #0063dd;
	--orange: #ff7300;
	--red: #ff3300;
}

/* Mobile Nav */
// Mobile Bar 

.mobile-bar {
	position: fixed;
	width: 100%;
	top: 50px;
	background: #fff;
	display: none;
	justify-content: space-between;
	padding: 0 25px;
	align-items: center;
	z-index: 999 !important;
	height: 80px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 12px; 


	a {
		text-decoration: none;
	}

	h2 {
		font-size: 2rem;
		font-family: "Unbounded", cursive;
		font-weight: 800;
		margin-left: 5px;
		color: black;
	}

	button {
		background: inherit;
		cursor: pointer;
		border: none;
		padding: 5px;
		margin-right: 20px;
	}

	.nav-bag {
		.bag-quantity {
			right: 0px;
			top: -5px;
		}
	}
}


.mobile-links {
	width: 100%;
	left: 0;
	top: -650px;
	display: flex;
	flex-direction: column;
	text-align: center;
	z-index: 2;
	background: white;
	color: #202020; 
	opacity: 0;
	transition: top, opacity, .1s;
	border-bottom: 1px solid #e7e7e7;
	position: fixed; 
}

.mobile-links a {
	color: #202020;
	font-size: 1.5rem;
	padding: 18px 0;
	text-decoration: none;
}

.mobile-links a {
	border-bottom: 1px solid #e7e7e7;
}

.show {
	top: 130px;
	opacity: 1;
	transition: top, opacity, .1s;
}

.mobile-standard {
	display: flex;
	a {	
		padding: 10px 5px;
		color: black;
		&:first-child {
			margin-right: 8px;
		}
	}
}

.nav-bag {
		position: relative;
	.bag-quantity {
		right: 95px;
		top: -8px;
		position: absolute;

		span {
			font-weight: 800;
		}
	}
}

/* Info bar */
.info-bar {
	z-index: 999 !important;
	height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--action);
	p {
		color: white;
		display: flex;
		align-items: center;
		font-size: 18px;
		justify-content: center;
		font-weight: 600;
	}
	b {
		font-size: 18px;
		padding: 2px;
		margin: 0 2px; 
		text-decoration: underline;
	}
	
	.info-divider {
		color: white !important;
	}
}

.info-bar > div {
	a:last-child {
		svg {
			font-size: 18px;
			margin-bottom: 1.5px;
		}
	}
}

// .info-loop {	
// 	position: relative;
// 	margin-right: 200px;
// 	p {
// 		position: absolute;
// 		opacity: 0;
// 		width: 500px;
// 		left: -150px;
// 		top: -11px;
// 		transition: opacity .5s;
// 		text-align: center;
// 		color: white;

// 		&.show {
// 			opacity: 1;
// 		}
// 	}	
// }

// LOGO
.logo {
	height: 70px;
}

// SCROLL BAR
.scroll-bar {
	width: 100%;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 12px; 
	height: 75px;
	background: #f5f5f5;
	display: none;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	z-index: 5;
	top: 0;
	padding: 0 calc((100vw - 1200px) / 2);
	
	.nav-bag {
		svg {
			width: 30px;
			margin-right: 5px;
		}
	}

	a {
		margin-right: 20px;
		color: black;
		&:hover {
			color: var(--action);
		}
	}

	img {
		height: 60px;
	}

	.warenkorb {
		.nav-bag {
			display: flex;
		}
	}

}

.scroll-bar-show {
	display: flex !important;
}

// Scroll to top
.scrollToTop {
	position: fixed;
	z-index: 5;
	bottom: 24px;
	left: 24px;
	cursor: pointer;
	background: var(--action);
	border-radius: 50%;
	display: none;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	img {
		width: 38px;
		height: 38px;
		color: white;
	}
}

// NAV BAR
.nav-bar {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 calc((100vw - 1200px) / 2);
	z-index: 2;
	
	a {
		color: black;
		text-align: center;
		text-decoration: none;
		transition: color, 40ms ;
		padding: 5px;
		&:hover {
			color: var(--action);
			transition: color, 40ms ;
		}
	}

	> a {
		flex: 1;
	}

	.standard-links {
		flex: 1;
		svg {
			color: black;
		}
	}
}

.nav-bar-bovenkant {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 40px;

	p {
		display: flex;
		align-items: center;
		font-size: 14px;

	}

	svg {
		height: 24px;
		width: 80px;
	}
}

.nav-bar-bovenkant-links {
	a {
		text-decoration: underline;
		font-size: 14px;
	}
}

.nav-bar-onderkant {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 1px solid #e7e7e7;
	width: 100%;
	height: 100px;

	div {
		display: flex;
		svg {
			width: 30px;
			margin-right: 5px;
		}
	}
}

.kundenservice {
	display: flex;
	margin-right: 20px;

	.kundenservice-wrapper {
		display: flex;
		flex-direction: column;
		text-align: start;

		p {
			font-size: 22px;
		}

		.status {
			display: flex;
			align-items: center;
		}

		.grun {
			background: #00c900;
			animation-name: grun;
		}

		.rot {
			animation-name: rot;
			background: #ca0000;
		}

		.pulse {
			width: 12px;
			height: 12px;
			border-radius: 50%;
			margin-left: 5px;
			animation-duration: 1.5s;
			animation-iteration-count: infinite;
			z-index: 3;
		}
	}

	.kundenservice-titel {
		font-size: 12px !important;
	}
}

@keyframes grun {
  0% {
    background-color: #00b9001a;
  }
  50% {
    background-color: #00c900;
  }
  100% {
    background-color: #00b9001a;
  }
}

@keyframes rot {
  0% {
    background-color: #ca00001a;
  }
  50% {
    background-color: #ca0000;
  }
  100% {
    background-color: #ca00001a;
  }
}

.warenkorb {
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		width: 30px;
		margin-right: 5px;
	}
}

.link-bar {
	background: #f5f5f5;
	padding: 0 calc((100vw - 1200px) / 2);
	height: 50px;
	display: flex;
	align-items: center;

	a {
		color: black;
		margin-right: 30px;

		&:hover {
			color: var(--action);
		}
	}
}

.standard-links {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	a {
		margin-right: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 45px;
		height: 45px;
		border: 1px solid #dcdcdc;	
		border-radius: 50%;
		position: relative;
	}

	svg {
		font-size: 20px;
		margin-top: 5px;
	}

}
.bag-quantity {
	position: absolute;
	background: var(--action);
	color: white;
	top: -5px;
	right: 5px;
	width: fit-content;
	padding: 0 5px;
	border-radius: 100px;
	text-align: center;
}

// Banner 
.banner {


	// .mySwiper {
	// 	height: 600px;
	// }

	// .carousel1 {
	// 	background: url("./components/assets/Banner2.png");
	// 	background-size: cover;
	// 	.a-button {
	// 		position: absolute;
	// 		line-height: 1.5;
	// 		font-size: 1.5rem;
	// 		bottom: 10%;
	// 		right: 25%;
	// 	}
	// }

	// .carousel2 {
	// 	background: url("./components/assets/Banner1.png");
	// 	background-size: cover;
	// 	.a-button {
	// 		position: absolute;
	// 		line-height: 1.5;
	// 		font-size: 1.5rem;
	// 		bottom: 10%;
	// 		left: 6.5%;
	// 	}
	// }

	// .carousel3 {
	// 	background: url("./components/assets/Banner3.png");
	// 	background-size: cover;
	// 	.a-button {
	// 		position: absolute;
	// 		line-height: 1.5;
	// 		font-size: 1.5rem;
	// 		bottom: 10%;
	// 		right: 25%;
	// 	}
	// }

	// .carousel4 {
	// 	background: url("./components/assets/Banner4.png");
	// 	background-size: cover;
	// 	.a-button {
	// 		position: absolute;
	// 		line-height: 1.5;
	// 		font-size: 1.5rem;
	// 		bottom: 45%;
	// 		right: 40.8%;
	// 	}
	// }

	// .carousel {
	// 	display: flex;
	// 	align-items: center;
	// 	justify-content: center;
	// 	position: relative;		
	// 	height: 600px;
	// 	width: 1200px;
	// }
	position: relative;
	

	img {
		width: 100%;
		height: 70vh;
		object-fit: cover;
		background-size: cover;
		background-position: center;
	}

	.banner-wrapper {
		position: absolute;
		text-align: center;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: white;
		padding: 30px;
	}

	.banner-title {
		color: var(--action);
		font-size: 3rem;
		font-weight: 800;
		margin-bottom: 10px;
	}

	.banner-description {
		font-size: 20px;
		margin-bottom: 1.5rem;
	}

	.banner-button {
		line-height: 2;
		font-size: 20px;
		background-color: var(--action);
	}
}

.main-home {
	width: 100%;
}
	
.allesbekijken {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 180px;
	height: 55px;
	text-decoration: none;
	margin: 30px auto 0 auto;
	font-size: 22px;
	background: black;
	color: white !important;

	&:hover {
		text-decoration: underline;
	}
}

@media screen and (max-width: 1000px) {
	.banner {
		margin-top: 120px;
	}

	.banner-wrapper {
		width: 90%;
	}

	.banner-title {
		font-size: 40px !important;
	}

	.main-home {
		margin-top: 150px;
		width: 100%;
	}

	.popup {
		width: 90% !important;
		height: 70% !important;
	}

	.closebutton {
		font-size: 30px !important;
	}
}

.backdrop {
	width: 100%;
	height: 100vh;
	z-index: 9998;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.4);
	position: absolute;

}

.no-scroll {
	overflow: hidden !important;
}

.show {
	display: flex !important;
}

.hide {
	display: none !important;
}

.popup {
	text-align: center;
	position: absolute;
	background: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	z-index: 9999;
	border: 5px solid var(--action);
	width: 500px;
	height: 420px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	.closebutton {
		position: absolute;
		top: 0;
		left: 0;
		padding: 10px;
		cursor: pointer;
		font-size: 25px;
		font-weight: 900;
	}

	.popuptitle {
		font-size: 60px;
	}

	.popuptext {
		font-size: 18px;
	}

	.popuptimer {
		margin-bottom: 15px;
		font-size: 100px;
	}

	.popupbutton {
		font-size: 20px;
		margin-bottom: 8px;
	}

	.closetext {
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}
}

.reverse {
	flex-direction: row-reverse;

	div {
		margin-right: 0 !important;
		margin-left: 60px;
	}
}

.info-container {
	margin: 60px 0;
	padding: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	div {
		margin-right: 60px;
		max-width: 600px;
		h1 {
			font-size: 60px;
			margin-bottom: 15px;
		}
		p {
			font-size: 18px;
			line-height: 1.5;
			margin-bottom: 15px;
		}
	}
	img {
		width: 700px;
	}
}

.stats-container {
	display: flex;
	align-items: center;
	justify-content: space-around;
	height: 340px;
	background: var(--action);
	margin-bottom: 60px;
	div {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		flex-wrap: wrap;
		width: 100%;
		max-width: 450px;
		background: #0068e7;
		img {
			height: 180px;
		}
		h1 {
			color: black;
			font-size: 30px;
			margin: 20px 0;
			white-space: nowrap;
		}
		.lastimg {
			height: 110px;
			margin: 34px 0;
		}
	}
}
@media screen and (max-width: 1000px) {
	.info-container {
		flex-direction: column-reverse;
		div {
			margin: 0 20px;
		}
		img {
			width: 100% !important;
			margin-bottom: 40px;
		}
	}

	.stats-container {
		flex-direction: column;
		height: auto !important;
		padding: 20px;
		div {
			margin: 20px 0;
		}
	}
}

.zubehor {
	.product {
		width: 300px;
		img {
			width: 300px;
			height: 300px;
		}
	}
}

.alle-produkte {
	margin-top: 0 !important;
	.product {
		width: 300px;
		img {
			width: 300px;
			height: 300px;
		}
	}

	.products-title {
		text-align: center;
		margin-bottom: 20px;
	}
}

.home-container {
	margin: 60px 0;
	.home-title {
		font-size: 45px;
		margin: 20px 0;
		text-align: center;
		padding: 10px;
	}
	.home-text {
		text-align: center;
		font-size: 20px;
	}
	
	.products {		
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		flex-direction: row;
	}
}

.confirmCart {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 10;
	background:rgba(0, 0, 0, 0.3);
	display: flex;
	align-items: center;
	justify-content: center;
	div {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		background: white;
		padding: 20px;	
		border-radius: 8px;
		width: fit-content;
		height: fit-content;
	}
}

@media screen and (max-width: 1000px) {
	.product {
		width: 300px !important;
		img {
			width: 300px !important;
			height: 300px !important; 
		}
	}
	.row {
		align-items: center;
		justify-content: center;
	}
}

.product-sale {
	position: absolute;
	background: var(--red);
	padding: 5px;
	color: white;
	font-weight: 600;
	font-size: 20px;
	right: 0;
}

.product {
	width: 420px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: column;
	position: relative;
	// box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
	margin: 20px;

	a {
		color: #444;
		text-decoration: none;
	}

	h1 {
		font-size: 20px;
		font-weight: 500;
		color: #444;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		&:hover {
			color: var(--action);
		}
	}

	img {
		width: 420px;
		height: 420px;
		margin-bottom: 5px;
		object-fit: cover;
		transition: border, 0.1s;
		border: 5px solid white;
		&:hover {
			border: 5px solid var(--action);
			transition: border, 0.1s;
		}
	}

	.saleprice {
		color: var(--red) !important;
		margin-left: 5px;
		font-size: 25px !important;
		text-decoration: line-through;
		font-weight: 600;
	}

	.prices {
		margin: 10px 0;
	}

	.price {
		font-size: 32px;
		font-weight: 600;
		color: black;
	}

	.details {
		margin:  0;
	}
	
	.rating {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	button {
		width: 100%;
		padding: 14px 0;
		font-weight: 600;
		font-size: 1.2em;
		margin-top: 5px;
		color: white;
		background: var(--action);
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.desc {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		font-size: 14px;
		color: #848484;
	}
}

.info-section {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	padding: 0 8px 50px 0;

	> div {
		max-width: 400px;
		margin-right: 50px;
	}

	h1 {
		font-size: 30px;
	}

	p {
		margin: 8px 0;
	}

	img {
		width: 400px;
		border-radius: 25px;
	}
}

@media screen and (max-width: 1000px) {
	.info-section {
		div {
			padding: 15px;
		}
	}
}

.stats {
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--secondary);
	margin: 80px 0 80px 0;
	padding: 50px 0;

	h2 {
		margin-bottom: 25px;
		font-size: 20px;
		font-weight: normal;
	}

	div { 
		margin: 0 30px;
		display: flex;
		align-items: center;

		img {
			width: 220px;
			margin-right: 5px;
		}

		p {
			margin-left: 10px;
		}
	}
}

.h1-section-titel {
	text-align: center;
	font-size: 40px;
	margin-bottom: 50px !important;
	border: none !important; 
}

.h1-section {
	display: flex;
	flex-direction: column;
	margin-top: 100px;
	padding: 0 calc((100vw - 1200px) / 2);
	margin: 50px 8px 8px 8px;

	h1 {
		border-bottom: 1px solid var(--secondary);
		margin-bottom: 10px;
		padding-bottom: 10px;
	}

	p {
		font-weight: 300;
		margin-bottom: 40px;
	}
}

.cart-register {
	color: white;
	width: 100%;
	height: 49px;
	text-align: center;
	margin-bottom: 10px;
	line-height: normal;
	text-decoration: none;
	background: #003c86;
	display: block;
}

.cart-container {
	padding: 10px calc((100vw - 1200px) / 2);

	.progress {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 40px 0 50px 0;

		svg {
			font-size: 20px;
			font-weight: 500;
			margin: 0 20px;
		}

		h1,h2 {
			font-size: 20px;
			font-weight: 600;
			&:first-child {
				color: var(--action);
			}
		}

		div {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.titles {
		display: flex;
		align-items: center;
		text-align: center;
		padding-bottom: 10px;
		border-bottom: 1px solid var(--secondary);
		h3:first-child {
			flex: 3 !important;
		}
		h3 {
			font-weight: 400;
			flex: 1
		}
	}

	.cart-items {
		margin-bottom: 20px;
		.cart-item {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;

			border-bottom: 1px solid var(--secondary);

			padding: 5px 0;

			.shipping-count {
				border: none !important;
			}

			.cart-product {
				display: flex;
				align-items: center;
				flex: 3;
				img {
					width: 120px;
					margin-right: 30px;
				}
				div {
					margin-right: 80px;
					h3 {
						font-size: 18px;
						font-weight: 600;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}
				}
			}

			.cart-product-price {
				flex: 1;
				font-size: 20px;
			}

			.cart-product-total-price {
				flex: 1;
				font-size: 20px;
				font-weight: 600;
			}

			.cart-product-quantity {
				flex: 1;
				display: flex;
				justify-content: center;
				align-items: center;

				.count {
					height: 35px;
					width: 35px;
					display: flex;
					justify-content: center;
					align-items: center;
					border-top: 1px solid #a2a2a2;
					border-bottom: 1px solid #a2a2a2;
					font-size: 18px;
				}

				button {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 35px;
					width: 35px;
					background: inherit;
					border: 1px solid #a2a2a2;
					border-radius: 0;
					color: #444;
					padding: 0;
					font-size: 30px;
					font-weight: 300;
				}
			}
			
		}
	}
}

.cart-summary {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	.clear-btn {
		margin-top: 25px;
		border: 1px solid #444;
		padding: 8px 14px;
		color: #767676;
		font-weight: 400;
		background: white
	}

	.cart-checkout {
		.subtotal {
			position: relative;
			display: flex;
			align-items: baseline;
			justify-content: space-between;

			.amount {
				font-size: 28px;
				font-weight: 700;
				padding: 10px 0 0 0;
			}

			p {
				font-size: 20px;
			}

			span {
				right: 0;
				color: #00c900;
				position: absolute;
			}
		}
		p {
			font-weight: 300;
			margin: 10px 0;
		}

		button {
			width: 100%;
			margin: 10px 0;
		}

		.shipping-protection {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 5px 0 15px 0;
		}

		.shipping-text {
			max-width: 300px;
			padding: 0 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			p {
				padding: 0;
				margin: 0;
				font-size: 13px;
				line-height: 16px;
			}
		}

		.shipping-text-top {
			width: 100%;
			display: flex;
			justify-content: space-between;
			h2 {
				font-size: 16px;
				margin-bottom: 5px;
			}
		}

		.shopswitch {
			height: 28px !important;
			width: 55px !important;
		}

		.shopslider:before {
			height: 20px;
			width: 20px;
			left: 4px;
			bottom: 4px;
		}

		.continue-shopping {
			margin: 0 0 10px 0;
			a {
				color: gray;
				text-decoration: none;
				display: flex;
				align-items: center;
				svg {
					margin-right: 5px;
				}
			}
		}
	}
}

@media screen and (max-width: 1000px) {
	.shipping-protection {
		.shipping-text {
			width: 230px;
		}
		img {
			width: 40px;
		}
		.shipping-text-top {
			h2 {
				margin-bottom: 2px !important;
			}
		}
	}
}

.cart-extra-info {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	padding: 10px;

	img:first-child {
		width: 110px;
		height: 110px;
	}

	img {
		margin: 10px 0;
		width: 100px;
		height: 100px;
	}
}

.cart-row {
	margin: 50px 0 100px 0;

	.review-card {
		width: 200px !important;
	}
	.review-image {
		width: 150px !important;
		margin: 0 auto;
	}
}

.cart-empty {
	margin-top: 80px !important;
	height: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-size: 24px;
	a {
		color: var(--action);
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			margin-right: 5px;
		}
	}
	p {
		margin-bottom: 10px;
	}
}

.breadcrumbs-container {
	display: flex;
	align-items: center;
	padding: 0 calc((100vw - 1400px) / 2);
	margin: 15px 0 10px 10px;
}

.cartbreadcrumbs {
	padding: 0 !important;
}

.singleproduct {
	padding: 0 calc((100vw - 2000px) / 2) !important;
}

.breadcrumbs {
	a {
		color: rgba(0, 0, 0, 0.6);
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}

// Checkout Success
.checkout-complete {
	max-width: 1200px;
	margin: 20px auto 500px auto;
	background: rgb(233, 255, 239);
	display: flex;
	padding: 20px;
	
	svg {
		width: 40px;
		height: 50px;
		color: rgb(74 222 128);
	}

	h2 {
		color: rgb(22 101 52);
		padding: 5px 10px;
	}
	p {
		color: rgb(21 128 61);
		padding: 5px 10px 15px 10px;
	}

	a {
		color: rgb(22 101 52);
		text-decoration: none;
		line-height: 3rem;
		padding: 10px;
		border-radius: 0.375rem;
		&:hover {
			background: rgb(201, 255, 214);
		}
		&:first-child {
			margin-right: 5px;
		}
	}
}

@media screen and (max-width: 1000px) {
	.checkout-complete {
		margin-top: 150px !important;
		flex-direction: column;
		margin-bottom: 300px;
	}
}

// Login / Register
.styled-form {
	box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  width: 400px;
  margin: 50px auto;
	padding: 30px;

  h1 {
    font-size: 24px;
  }

  p {
    margin-bottom: 20px;
  }

  div, section {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
  }

  label {
    margin-bottom: 3px;
    font-weight: 500;
    font-size: 15px;
  	color: #616161;
  }

  input {
    padding: 7px 8px;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    &:focus {
      border-color: white;
	    outline: 2px solid var(--action);
    }
  }

  button,
  input {
		font-size: 16px;
    width: 100%;
    border-radius: 0.375rem;
    border: 1px solid rgb(220, 220, 220);
  }

  button {
    margin-top: 12px;
  }

  p {
    font-size: 14px;
    a {
      color: var(--action);
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    margin-top: 20px;
    box-shadow: none;
    width: 100%;
  }
}

.form-border {
	width: 100%;
	height: 1px;
	background: #e7e7e7;
	margin: 20px 0 20px 0;
}

.switch {
	margin-top: 3px;
	position: relative;
	display: inline-block;
	width: 60px !important;
	height: 34px;
}

.slider {
	border-radius: 34px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .2s;
  transition: .2s;	
}

.slider:before {
	border-radius: 50%;
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .2s;
  transition: .2s;
}

.showSwitch {
  background-color: var(--action);
}

.showSwitch:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}



// Product

.shaking-button {
  &.shake-animation {
    animation: shake 0.3s ease-in-out;
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-8px);
    }
    50% {
      transform: translateX(8px);
    }
    75% {
      transform: translateX(-8px);
    }
    100% {
      transform: translateX(0);
    }
  }
}


.styled-product {
	padding: 0 calc((100vw - 1400px) / 2);
}

.bb-show {
	bottom: 0px !important;
	transition: bottom, .1s ease-in-out;
}

.bottombuy {
	z-index: 500;
	position: fixed;
	bottom: -100px;
	left: 0;
	width: 100%;
	background: var(--secondary);
	border-top: 1px solid #d6d6d6;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 120px 8px 10px;
	transition: bottom, .1s ease-in-out;

	img {
		width: 50px;
		height: 50px;
		border-radius: 5px;
		border: none !important;
	}

	.bottombuy-product {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
	}


	.bottombuy-info {
		margin-left: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.bottombuynow {
		padding: 12px 42px;
	}
}

@media screen and (max-width: 1000px) {
	.bottombuy {
		flex-direction: column;
		padding: 8px 90px 8px 10px;
	}

	.bottombuynow {
		margin-top: 5px;
		padding: 8px 42px !important;
		width: 100% !important;
	}
}

.product-container {
	display: flex;
	justify-content: space-between;
	margin-bottom: 50px;
	position: relative;

	.ranking {
		position: absolute;
		background: var(--action);
		color: white;
		font-size: 16px;
		font-weight: 700;
		padding: 8px 12px 8px 8px;
		top: 0;
		left: 0;
		border-bottom-right-radius: 20px;
		text-transform: uppercase;
	}

	.img-container {
		position: relative;
		margin-right: 50px;
		width: 700px;
		.product-sale {
			font-size: 24px;
			right: 20px !important;
			top: 20px !important;
		}
	}

	.img-container .mainImage {
		width: 700px;
		height: 700px !important;
		padding: 20px;
		object-fit: cover;
		flex: 6;
	}

	.images {
		padding: 0 20px;
		overflow-x: scroll !important;	
		width: 100%;
		display: flex;
		align-items: center;
		img {
			margin: 2px 2px 2px 0 !important;
			width: 80px;
			height: 80px;
			border: 2px solid #d4d4d4;
			object-fit: cover;
			cursor: pointer;
		}
	}

	.product-details {
		flex: 6;
		h1 {
			font-size: 30px;
		}
		.rating {
			display: flex;
			margin: 5px 0;
			p {
				margin-left: 5px;
				color: rgba(0,0,0,0.36);
			}
		}
		.stock {
			font-weight: 400;
		}
		.product-prices {
			display: flex;
			flex-direction: column;
			margin: 20px 0;
		}
		.product-price {
			font-weight: bold;
			font-size: 50px;
			color: black;
			margin: -8px 0 0 0;
		}
		.product-sales {
			display: flex;
			align-items: center;
		}
		.sale-display {
			color: #00c900;
			text-decoration: none !important;
			margin-left: 10px;
		}
		.sale-price {
			text-decoration: line-through;
			color: rgb(180, 180, 180);
			-webkit-text-decoration-color: rgb(180, 180, 180); /* For Safari */
			text-decoration-color: rgb(180, 180, 180);
			font-weight: normal;
			-webkit-text-decoration-thickness: 2px; /* For Safari */
			text-decoration-thickness: 2px;
			margin: 0;
			font-size: 30px;
		}
		.mwst {
			margin-bottom: 25px;
			color: rgba(0,0,0,0.36);

		}
		.product-soldout {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 22px;
			width: 100%;
			padding: 16px;
			margin-bottom: 15px;
			background:rgba(0, 0, 0, 0.3);
			cursor:not-allowed;
		}
		.product-add-to-cart {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 22px;
			width: 100%;
			padding: 16px;
			margin-bottom: 15px;
			svg {
				margin-right: 5px;
			}
		}
		.deliverydate {
			border-top: 2px dotted rgba(0,0,0,0.15);
			border-bottom: 2px dotted rgba(0,0,0,0.15);
			margin: 5px 20px 15px 20px;
			padding: 10px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			svg {
				margin-right: 8px;
				width: 24px;
				height: 24px;
			}
			.date {
				font-weight: 600;
			}
			.versand {
				color: rgba(0, 0, 0, 0.6);
			}
		}
		.prod-info {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			background: #f6f7f9;
			padding: 18px 0;
			border-radius: 8px;
			margin: 10px 0;
			p {
				font-size: 18px;
				margin: 0 10px;
			}
		}
		.product-purchase {
			margin-bottom: 10px;
			width: 100%;
			background:  white;
			color: #444;
			border: 5px solid #dcdcdc;
			padding: 10px;
		}
	}

	.veelgestelde-vragen {
		margin-top: 20px;
		div {
			margin-top: 10px;
		}
		h1 {
			border-bottom: 1px solid #d4d4d4;
			font-size: 20px;
			padding: 5px;
		}
		p {
			padding: 5px;
		}
	}

	

	.payments {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 15px;
		width: 100%;
		svg {
			margin: 0 3px;
		}
	}

	.more-options {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.option-selected {
		border-color: rgba(0, 115, 255) !important;
		background: rgba(0, 115, 255, 0.1);
		border-width: 2px !important;
	}

	.option {
		-webkit-user-select: none; /* Safari */
		-ms-user-select: none; /* IE 10 and IE 11 */
		user-select: none; /* Standard syntax */
		width: 200px;
		padding: 5px;
		border-radius: 2px;
		border: 1px solid #bdbdbd;
		cursor: pointer;
		margin-bottom: 10px;
		p {
			display: flex;
		}
	}

	.option-selected {
		.centercircle {
			border-color: var(--action) !important;
			.innercircle {
				display: block !important;
			}
		}
	}

	.centercircle {
		border-radius: 50%;
		border: 1px solid #8b8b8b;
		margin-right: 5px;
		width: 18px;
		height: 18px;
		position: relative;
	}

	.innercircle {
		display: none;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 50%;
		background: var(--action);
		width: 12px;
		height: 12px;
		z-index: 9;
	}

	.extra-info {
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		img:first-child {
			width: 160px;
			height: 160px;
			margin-right: 50px;
		}
		img {
			width: 150px;
			height: 150px;
			margin: 30px 0;
		}
	}

	@media screen and (max-width: 1000px) {
		.extra-info {
			img {
				margin-right: 20px !important;
			}
		}
	}
	
	// .product-info {
	// 	h2 {
	// 		margin-top: 10px;
	// 	}
	// 	p {
	// 		font-size: 15px;
	// 	}		
	// }

	// .warning {
	// 	padding: 10px;
	// 	background: rgba(255, 255, 0, 0.2);
	// }

}

.showoverlay2 {
	display: block !important;
}
.overlay2 {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: rgba(0,0,0,0.5);
	z-index: 999;
	overflow: hidden !important;
}

.overlay-wrapper {
	position: absolute;
	width: 90vw;
	height: 95%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: white;
}

.description {
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	p {
		display: block;
		margin-top: 1em;
		margin-bottom: 1em;
		margin-left: 0;
		margin-right: 0;
	}
}

@media screen and (max-width: 1000px) {
	.description {
		img {
			width: 100% !important;
		}
	}
}

// Random Products
.rproducts {
	margin-bottom: 50px;
	h2 {
		margin-bottom: 30px;
	}

	.rproduct-wrapper {
		display: flex;
		justify-content: flex-start;		
		overflow-x: scroll;

	}

	.rproduct {
		border: 1px solid #d4d4d4;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
		margin: 5px 5px;
		padding: 15px 25px;
		height: 300px;
		width: 200px;

		> a {
			height: 300px;
			text-decoration: none;
		}

		img {
			object-fit: cover;
			width: 150px;
			height: 150px;
		}

		h3 {
			margin-top: 10px;
			color: #444;
			font-weight: 500;
			font-size: 16px;
			width: 140px;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
		.details {
			display: flex;
			flex-direction: column;
		}
		.desc {
			color: #444;
			font-weight: 300;
			font-size: 16px;
			margin: 10px 0 auto 0;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
		.price {
			font-size: 24px;
			font-weight: 600;
			color: black;
		}
	}
}

@media screen and (max-width: 1000px) {
	.rproducts {
		padding: 0 20px;
	}
}

// Reviews

.reviews-container {

	.reviews-title {
		text-align: center;
		font-size: 40px;
		margin-bottom: 50px; 
	}
	.row {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	.review-card {
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin: 15px 8px;
		border-radius: 4px;
		width: 250px;
		padding: 10px 20px 20px 20px;
		height: min-content;
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	}
	.review-image {
		margin-top: 5px;
		object-fit: cover;
		width: 210px;
		margin-bottom: 10px;
	}
}

@media screen and (max-width: 1000px) {
	.row {
		flex-direction: column !important;
		padding: 0 20px;
	}
	.column {
	}
}

.reviews-wrapper {
	margin-bottom: 80px;
	h1 {
		margin: 80px 0 10px 0;
		font-size: 28px;
	}
}

.reviews {
	display: flex;
	justify-content: space-between;

	.reviews-customer {
		color: #686868;
		width: 75%;
		> div {
			border: 1px solid #dcdcdc;
			border-radius: 3px;
			margin-bottom: 10px;
			padding: 5px 10px;
			.review-info {
				display: flex;
				align-items: center;
				justify-content: space-between;
				
				div {
					display: flex;
					align-items: center;
				}
				p {
					color: gray;
					font-size: 16px;
				}
			}
			h2 {
				margin-right: 10px;		
				font-weight: 500;
			}
			b {
				font-style: italic;
				font-weight: 600;
			}
			.review-message {
				margin: 0 !important;
			}

		}
	}

	.write-review {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border: 1px solid #dcdcdc;
		border-radius: 3px;
		padding: 20px;
		text-align: center;
		width: 280px;

		button {
			width: 100%;
		}

		h2 {
			margin-bottom: 5px;	
		}

		.review-form {
			display: flex;
			flex-direction: column;
			h2 {
				margin: 15px 0 0 0;
			}
			.review-rating {
				margin: 10px 0;
				width: 100px;
				font-size: 40px;
			}
			div label {
				margin-bottom: 2px;
				font-weight: 500;
				font-size: 15px;
				color: #616161;
			}
			input, textarea {
				width: 100%;
				padding: 7px 8px;
				font-size: 15px;
				border: 1px solid #d4d4d4;
				border-radius: 5px;
				margin-bottom: 5px;
				&:focus {
					border-color: white;
					outline: 2px solid var(--action);
				}
			}
			button {
				margin-top: 10px;
			}
		}
	}
}

// 1000 PX
@media screen and (max-width: 1000px) {
	.nav-bar {
		display: none;
	}
	.info-bar {
		width: 100%;
		z-index: 5;
		position: fixed;
		height: 50px;
		top: 0;
		flex-direction: column;
		p:last-child {
			margin-top: 0px;
		}
		p {
			white-space: nowrap;
			font-size: 14px;
		}
		.info-divider {
			display: none;
		}
	}
	.link-bar {
		display: none;
	}
	.mobile-bar {
		display: flex;
		h2 {
			font-size: 25px;
		}
	}
	.scroll-bar {
		display: none !important;
	}
	.products {
		width: 100% !important;
		margin: 0 !important;
		flex-direction: column !important;
		align-items: center;
	}
	.stats {
		flex-direction: column;
		div {
			margin: 30px 0;
			align-items: center;
		}
	}
	.info-section {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		div {
			margin: 0;
		}
		img {
			width: 100%;
		}
	}
	.footer-links {
		flex-direction: column;
		justify-content: center;
		text-align: center;
		div {
			margin: 20px 0;
		}
	}
	.progress {
		display: none !important;
	}

	.titles {
		display: none !important;
	}

	.cart-item {
		flex-direction: column;
	}

	.cart-product {
		flex-direction: column;
		img {
			margin: 10px 0 !important;
			width: 100px !important;
		}
		div {
			margin: 0 !important;
			h3 {
				margin: 0 10px 20px 10px;
			}
		}
	}

	.cart-product-price {
		display: none;
	}

	.cart-product-total-price,.cart-product-quantity {
		margin-bottom: 10px;
	}

	.cart-summary {
		flex-direction: column;
		margin: 20px;
		align-items: flex-start;

		.clear-btn {
			margin-bottom: 20px;
			font-size: 12px;
		}
	}

	.cart-checkout {
		span {
			padding: 0 !important;
		}
	}
}

.description {
	margin: 0 0 80px 0;
	h2 {
		font-size: 24px;
		margin-bottom: 30px;
	}
	ul {
		padding: 0 20px;
	}

	li {
		margin: 0;
	}
}

@media screen and (max-width: 1000px) {
	.product-container {
		flex-direction: column;
		align-items: center;
	}

	.breadcrumbs {
		margin-top: 140px !important;
	}

	.cart-summary {
		flex-direction: column-reverse;
		align-items: center;
		.clear-btn {
			font-size: 16px;
		}
	}

	.img-container {
		margin: 0 !important;
		.mainImage {
			width: 100% !important;
		}
		width: 100% !important;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}

	.img-container .images {
		img {
			width: 80px !important;
			height: 80px;
		}
		width: 100%;
	}

	.img-container img {
		margin-right: 0 !important;
		width: 200px !important;
	}

	.product-details {
		width: 100% !important;
		padding: 20px;
	}

	.description {
		padding: 20px !important;
		margin-top: 10px !important;
		div {
			margin-bottom: 30px;
		}
	}

	.reviews-wrapper {
		padding: 20px;
		h1 {
			margin: 0 0 10px 0 !important;
		} 
	}

	.reviews {
		flex-direction: column;

		.reviews-customer {
			width: 100%;
			> div {
				.review-info {
					div {
						display: block;
						margin-bottom: 8px;
						h2 {
							font-size: 18px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
						}
						p {
							font-size: 14px;
						}
					}
				}
			}
		}

		.write-review {
			width: 100% !important;
			.review-form h2 {
				margin: 15px 0 0 0 !important;
			}
		}
	}
}



.beleid {
	padding: 0 calc((100vw - 1200px) / 2);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 50px 20px;
	height: fit-content !important;

	h1 {
		margin-bottom: 15px;
	}
}

@media screen and (max-width: 1000px) {
	.beleid {
		margin-top: 150px;
	}
}

.link-active {
	margin-left: 8px;
	color: var(--action) !important;
}

// Footer 
.footer {
	background: #001438;

	.footer-cr {
		display: flex;
		align-items: center;
		padding: 20px calc((100vw - 1200px) / 2);
		justify-content: space-between;
		color: white;
		font-size: 14px;
		background: #00102e;
		a {
			color: white;
		}
	}

	.footer-top {
		width: 100%;
		padding: 20px calc((100vw - 1200px) / 2);
		border-bottom: 1px solid white;
		display: flex;
		align-items: center;
		justify-content: space-between;
		svg {
			margin-left: 8px;
		}
	}

	.footer-links {
		display: flex;
		justify-content: space-between;
		padding: 70px calc((100vw - 1200px) / 2);

		p {
			color: white;
			padding: 8px 0;
		}

		h1 {
			color: white;
			border-bottom: 3px solid white;
			width: fit-content;
			padding: 2px 0;
			margin-bottom: 10px;
		}

		a {
			color: white;
			width: fit-content;
			text-decoration: none;
			padding: 8px 0;
			&:hover {
				text-decoration: underline;
				color: var(--action);
			}
		}

		div {
			display: flex;
			flex-direction: column;
		}

		.abonnieren {
			input {
				margin-top: 8px;
				margin-bottom: 15px;
				width: 250px;
				font-size: 1rem;
				color: white;
				border-radius: 4px;
				border: 2px solid white;
				padding: 10px;
				outline: none;
				background: inherit;
			}
		}
	}


}

.cartpayments {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

@media screen and (max-width: 1000px) {
	.footer-top {
		flex-direction: column;
		justify-content: center;
		.payments {
			margin-top: 20px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
		}
	}

	.footer-links {
		display: flex;
		padding: 70px 50px !important;
		justify-content: center;
		.company-info {
			text-align: left;
		}
		.abonnieren {
			width: fit-content;
		}
	}

	.footer-cr {
		padding: 30px 15px !important;
		a {
			white-space: nowrap;
		}
	}
}

.not-found {
	height: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
	h2 {
		font-size: 50px;
		margin-right: 10px;
	}
}

.paypal-button {
	border-color: #003087;
	padding: 10px 15px;	
	text-align: center;
	border-width: 3px;
	border-radius: 0.375rem;
	margin: 10px 0 15px 0;
}

.paypal-button legend {
	height: 30px;
}

.faq {
	padding: 0rem calc((100vw - 1200px) / 2) 3rem calc((100vw - 1200px) / 2);
	margin: 0 10px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	box-sizing: border-box;

	> h1 {
		margin-bottom: 18px;
	}

	div {
		position: relative;
		overflow: hidden;
	}

	div h1 {
		padding: 12px 18px;
		cursor: pointer;
		font-size: 24px;
		background: #ededed;
		border-bottom: 3px solid var(--action);
	}

	div p {
		max-height: 0;
		font-size: 18px;
		transition: max-height, 0.2s;
	}

	.arrow {
		position: absolute;
		right: 18px;
		top: 18px;
		font-size: 35px;
		transition: transform, 0.2s;
	}

	.active {
		transition: transform, 0.2s;
		transform: rotate(180deg);
	}

	.active-p {
		padding: 18px;
		max-height: fit-content;
		transition: max-height,0.2s;
	}

}

.contact-text {
	p {
		font-size: 18px;
	}

	.kundenservice {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 10px 0;
	}
}

@media screen and (max-width: 1000px) {
	.contact-text {
		padding: 10px;
	}
}

.contact-form {
	margin: 3rem 0 4rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.contact-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	.contact-input {
		margin: 0 10px;
	}
}

.contact-input {
	text-align: left;
	display: flex;
	flex-direction: column;

	label {
		margin-bottom: 5px;
	}
}

.contact-form input {
	width: 250px;
	padding: 10px;
	font-size: 1rem;
	margin: 0 ;
}

.contact-form textarea {
	width: 520px;
	font-size: 1rem;
	padding: 10px;
}

.contact-textarea {
	text-align: left;
	display: flex;
	flex-direction: column;

	label {
	margin-top: 20px;
	margin-bottom: 5px;
	}
}

.contact-button {
	width: 520px;
	margin-top: 40px;
}

@media screen and (max-width: 1000px) {
	.contact-form {
		width: 100%;
	}

	.contact-input {
	}

	.contact-wrapper {
		flex-direction: column;
		width: 100%;
	}	

	.contact-form textarea {
		width: 300px;
		font-size: 1rem;
		padding: 10px;
	}

	.contact-button {
		width: 80%;
	}
}

// Mailing list

.mail-list {
	width: 100%;
	background: var(--action);
	padding: 40px 20px;
	color: white;
	margin: 50px 0 0 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	h1 {
		font-size: 30px;
	}

	div {
		margin-top: 20px;
		display: flex;
		flex-wrap: nowrap;
	}

	input {
		padding: 10px;
		border: none;
		outline: none;
		font-size: 18px;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	}

	button {
		background: white;
		padding: 7px;
		background: #003087;
		border-radius: 0;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}
}

.orders {
	padding-bottom: 50px;
}

.orders .breadcrumbs {
	svg {
		margin-left: 50px;
	}
}

.order-none {
	text-align: center;
}

.orders-title {
	width: 1000px;
	font-size: 28px;
	font-weight: bold;
	margin: 1rem auto;
}

.complete-wrapper {
	display: flex;
	flex-direction: column-reverse;
}

.orders-wrapper {
	border: 1px solid lightgray;
  border-radius: 5px;
	margin: 30px auto;
	width: 1000px;
	@media screen and (max-width: 920px) {
		width: 100% !important;
	}
}

.order-info {
	display: flex;
	background: #ebebeb;
	align-content: center;
	justify-content: space-between;
	border-bottom: 1px solid lightgray;
	height: min-content;
	padding: 10px;
	width: 100%;
	margin-bottom: 1rem;
	div {
		padding: 8px 20px !important;
	}
}

.order-address {
	margin-right: auto !important;
	position: relative;
}

.order-name {
	color: var(--action);

	&:hover {
		color: #003087;
	}
}

.order-show {
	display: block !important;
}

.order-information {
	display: none;
	position: absolute;
	background: white;
	border: 1px solid #d4d4d4;
	border-radius: 4px;
	padding: 20px !important;
	width: max-content !important;
	p {
		white-space: nowrap;
	}
}

.order-products {
	display: flex;
	width: 100%;
	padding: 10px 20px;
	justify-content: space-between;
}

.order-image-desc {
	display: flex;
	align-items: center;
	width: 100%;
	.anzahl {
		color: black;
	}
}

.order-products-desc {
	margin-left: 20px;
}

.order-text {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.rechnung {
	padding: 20px;
	button {
		margin-right: 10px;
		border: 2px solid var(--action);
	}
	button, a {
		font-size: 16px;
	}
	a {
		border: 1px solid black;
		font-weight: normal;
		background: inherit;
		color: black;
	}
	a:focus {
		background: inherit;
	}
}

@media screen and (max-width: 1000px) {
	.orders {
		width: 100vw;	
	}
	.orders .breadcrumbs {
		svg {
			margin-left: 0;
		}
	}
	.orders-title {
		width: 100vw;
		text-align: center;
		margin: 1rem 0 0 0;
	}
	.complete-wrapper {
		padding: 0 20px;
	}
	.order-info {
		display: flex;
		flex-direction: row !important;
		flex-wrap: wrap;
		div {
			width: 140px;
			padding: 20px;
		}
	}
	.order-image-desc {
		img {
			width: 60px;
		}
	}
	.rechnung {
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		button {
			margin-right: 0;
			border: 2px solid var(--action);
		}
		.a-button {
			width: 280px;
			line-height: 1.4;
			text-align: center;
			margin-top: 10px;
			height: 50px;
		}
	}
}

// Profile
.profile {
	padding: 3rem 0;
	display: flex;
	justify-content: center;
	background: rgba(0, 0, 0, 0.1);
}

.profile-info {
	border-radius: 3px;
	background: white;
	padding: 30px;
	img {
		width: 150px;
		height: 150px;
		border-radius: 50%;
		margin-bottom: 10px;
	}
	div p:last-child {
		margin: 5px 0 20px 0;
		font-weight: 700;
	}
	button {
		margin-top: 20px;
	}
}

.uitloggen {
	background: crimson;
	width: 100%;
}

.profile-edit {
	display: flex;
	flex-direction: column;
	border-radius: 3px;
	background: white;
  width: 400px;
	margin-left: 10px;
	padding: 30px;
	.password {
		margin-bottom: 20px;
	}
	.firma {
		margin: 20px 0;
	}
	div {
		display: flex;
		flex-direction: column;
	}
	button {
		margin-top: 20px;
	}

	label {
		margin-bottom: 0.2rem;
		font-size: 15px;
		color: gray;
	}
	input {
		margin-bottom: 1rem;
		border-bottom: 1px solid gray;
		width: 100%;
		padding: 7px 8px;
		font-size: 1.2rem;
		border: 1px solid #d4d4d4;
		border-radius: 5px;
		margin-bottom: 5px;
		&:focus {
			border-color: white;
			outline: 2px solid var(--action);
		}
	}
}

@media screen and (max-width: 1000px) {
	.profile {
		margin-top: 70px;
		flex-direction: column;
	}

	.profile-edit {
		margin-left: 0;
	}
}

.editor {
	button {
		background: white;
		color: black;
		padding: 5px;
		border: 1px solid black;
		font-weight: normal;
		margin: 3px;
	}
}


.ProseMirror {
	border: 3px solid black;
	border-radius: 4px;
	padding: 20px;
	margin: 20px 0;
	&:focus {
		outline: none;
		border-color: var(--action);
	}
}